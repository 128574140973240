ion-modal.fullscreen {
  --height: 100%;
  --width: 100%;
}

ion-modal.fullheight {
  --height: 100%;
}

ion-modal.category-page {
  --height: 100%;
  --width: 750px;
}

ion-modal.container-width {
  --height: 100%;
  --width: 1140px;
}
