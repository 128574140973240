.w-100 {
  width: 100% !important;
}

.d-block {
  display: block !important;
}

.inline-dotted {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

button.btn-lg {
  font-size: 24px;
  line-height: 44px;
  text-transform: capitalize;
}

h2 {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px;
  margin: 0 0 5px 0;
}

ion-toast {
  --background: rgb(12, 12, 12);
  --color: rgba(255, 255, 255, 0.8);
  --border-color: rgb(12, 12, 12);
}

.mat-tooltip {
  font-size: 18px !important;
}

@media (min-width: 992px) {
  .hide-md {
    display: none;
  }
}

@media (max-width: 992px) {
  .hide-xs {
    display: none;
  }
}

.dotted {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
