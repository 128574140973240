.ql-container,
.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  border: none;
}

.ql-editor,
.ql-toolbar,
.ql-toolbar.ql-snow {
  padding: 0;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: end;
}

iframe {
  display: block;
  width: 100% !important;
  aspect-ratio: 16 / 9;
  max-width: 688px !important;
  margin: 0 auto;
}
